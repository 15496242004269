import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _20fe248c = () => interopDefault(import('../pages/aboutUs.vue' /* webpackChunkName: "pages/aboutUs" */))
const _625870db = () => interopDefault(import('../pages/brands.vue' /* webpackChunkName: "pages/brands" */))
const _33c19832 = () => interopDefault(import('../pages/cc/index.vue' /* webpackChunkName: "pages/cc/index" */))
const _3d8f91be = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _852bdcc4 = () => interopDefault(import('../pages/ds.vue' /* webpackChunkName: "pages/ds" */))
const _5af21398 = () => interopDefault(import('../pages/favorites.vue' /* webpackChunkName: "pages/favorites" */))
const _475a0cb5 = () => interopDefault(import('../pages/hermes.vue' /* webpackChunkName: "pages/hermes" */))
const _40a00008 = () => interopDefault(import('../pages/payments.vue' /* webpackChunkName: "pages/payments" */))
const _6efc5d55 = () => interopDefault(import('../pages/random/index.vue' /* webpackChunkName: "pages/random/index" */))
const _405153f8 = () => interopDefault(import('../pages/shield.vue' /* webpackChunkName: "pages/shield" */))
const _a0c68a74 = () => interopDefault(import('../pages/shippings.vue' /* webpackChunkName: "pages/shippings" */))
const _d9a0acf8 = () => interopDefault(import('../pages/success.vue' /* webpackChunkName: "pages/success" */))
const _24315170 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _c0e905e8 = () => interopDefault(import('../pages/track.vue' /* webpackChunkName: "pages/track" */))
const _4b980bfd = () => interopDefault(import('../pages/wizard/index.vue' /* webpackChunkName: "pages/wizard/index" */))
const _5d67f2dc = () => interopDefault(import('../pages/hermes-service/order.vue' /* webpackChunkName: "pages/hermes-service/order" */))
const _43e99913 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _563208ec = () => interopDefault(import('../pages/blog/article/_blog.vue' /* webpackChunkName: "pages/blog/article/_blog" */))
const _75f5529a = () => interopDefault(import('../pages/blog/category/_category.vue' /* webpackChunkName: "pages/blog/category/_category" */))
const _48e001be = () => interopDefault(import('../pages/blog/tag/_tag.vue' /* webpackChunkName: "pages/blog/tag/_tag" */))
const _32c2ad72 = () => interopDefault(import('../pages/account/_tab.vue' /* webpackChunkName: "pages/account/_tab" */))
const _61571d00 = () => interopDefault(import('../pages/blog/_q.vue' /* webpackChunkName: "pages/blog/_q" */))
const _0abcc78b = () => interopDefault(import('../pages/cart/_step.vue' /* webpackChunkName: "pages/cart/_step" */))
const _61b6d5f9 = () => interopDefault(import('../pages/categories/_category.vue' /* webpackChunkName: "pages/categories/_category" */))
const _64a8bb1b = () => interopDefault(import('../pages/category/_category.vue' /* webpackChunkName: "pages/category/_category" */))
const _4e45ac41 = () => interopDefault(import('../pages/cc/_ba/index.vue' /* webpackChunkName: "pages/cc/_ba/index" */))
const _2de61986 = () => interopDefault(import('../pages/f/_projection.vue' /* webpackChunkName: "pages/f/_projection" */))
const _5aa2f1dc = () => interopDefault(import('../pages/featured/_projection.vue' /* webpackChunkName: "pages/featured/_projection" */))
const _09c81e03 = () => interopDefault(import('../pages/forget/_key.vue' /* webpackChunkName: "pages/forget/_key" */))
const _eb096de6 = () => interopDefault(import('../pages/item/_id.vue' /* webpackChunkName: "pages/item/_id" */))
const _70e2870d = () => interopDefault(import('../pages/list/_field.vue' /* webpackChunkName: "pages/list/_field" */))
const _4fe1a3f4 = () => interopDefault(import('../pages/preview/_id.vue' /* webpackChunkName: "pages/preview/_id" */))
const _1f930f42 = () => interopDefault(import('../pages/random/_random1/index.vue' /* webpackChunkName: "pages/random/_random1/index" */))
const _4fa2840c = () => interopDefault(import('../pages/search/_q.vue' /* webpackChunkName: "pages/search/_q" */))
const _a6bb4ffa = () => interopDefault(import('../pages/status/_status.vue' /* webpackChunkName: "pages/status/_status" */))
const _63b90a96 = () => interopDefault(import('../pages/cc/_ba/_family/index.vue' /* webpackChunkName: "pages/cc/_ba/_family/index" */))
const _0eeddb56 = () => interopDefault(import('../pages/random/_random1/_random2/index.vue' /* webpackChunkName: "pages/random/_random1/_random2/index" */))
const _19b4de00 = () => interopDefault(import('../pages/random/_random1/_random2/_random3/index.vue' /* webpackChunkName: "pages/random/_random1/_random2/_random3/index" */))
const _43d7a5de = () => interopDefault(import('../components/core/catalogMap.vue' /* webpackChunkName: "components/core/catalogMap.vue" */))
const _2efe4ef8 = () => interopDefault(import('../components/core/dynamicMap.vue' /* webpackChunkName: "components/core/dynamicMap.vue" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutUs",
    component: _20fe248c,
    name: "aboutUs"
  }, {
    path: "/brands",
    component: _625870db,
    name: "brands"
  }, {
    path: "/cc",
    component: _33c19832,
    name: "cc"
  }, {
    path: "/contact",
    component: _3d8f91be,
    name: "contact"
  }, {
    path: "/ds",
    component: _852bdcc4,
    name: "ds"
  }, {
    path: "/favorites",
    component: _5af21398,
    name: "favorites"
  }, {
    path: "/hermes",
    component: _475a0cb5,
    name: "hermes"
  }, {
    path: "/payments",
    component: _40a00008,
    name: "payments"
  }, {
    path: "/random",
    component: _6efc5d55,
    name: "random"
  }, {
    path: "/shield",
    component: _405153f8,
    name: "shield"
  }, {
    path: "/shippings",
    component: _a0c68a74,
    name: "shippings"
  }, {
    path: "/success",
    component: _d9a0acf8,
    name: "success"
  }, {
    path: "/terms",
    component: _24315170,
    name: "terms"
  }, {
    path: "/track",
    component: _c0e905e8,
    name: "track"
  }, {
    path: "/wizard",
    component: _4b980bfd,
    name: "wizard"
  }, {
    path: "/hermes-service/order",
    component: _5d67f2dc,
    name: "hermes-service-order"
  }, {
    path: "/",
    component: _43e99913,
    name: "index"
  }, {
    path: "/blog/article/:blog?",
    component: _563208ec,
    name: "blog-article-blog"
  }, {
    path: "/blog/category/:category?",
    component: _75f5529a,
    name: "blog-category-category"
  }, {
    path: "/blog/tag/:tag?",
    component: _48e001be,
    name: "blog-tag-tag"
  }, {
    path: "/account/:tab?",
    component: _32c2ad72,
    name: "account-tab"
  }, {
    path: "/blog/:q?",
    component: _61571d00,
    name: "blog-q"
  }, {
    path: "/cart/:step?",
    component: _0abcc78b,
    name: "cart-step"
  }, {
    path: "/categories/:category?",
    component: _61b6d5f9,
    name: "categories-category"
  }, {
    path: "/category/:category?",
    component: _64a8bb1b,
    name: "category-category"
  }, {
    path: "/cc/:ba",
    component: _4e45ac41,
    name: "cc-ba"
  }, {
    path: "/f/:projection?",
    component: _2de61986,
    name: "f-projection"
  }, {
    path: "/featured/:projection?",
    component: _5aa2f1dc,
    name: "featured-projection"
  }, {
    path: "/forget/:key?",
    component: _09c81e03,
    name: "forget-key"
  }, {
    path: "/item/:id?",
    component: _eb096de6,
    name: "item-id"
  }, {
    path: "/list/:field?",
    component: _70e2870d,
    name: "list-field"
  }, {
    path: "/preview/:id?",
    component: _4fe1a3f4,
    name: "preview-id"
  }, {
    path: "/random/:random1",
    component: _1f930f42,
    name: "random-random1"
  }, {
    path: "/search/:q?",
    component: _4fa2840c,
    name: "search-q"
  }, {
    path: "/status/:status?",
    component: _a6bb4ffa,
    name: "status-status"
  }, {
    path: "/cc/:ba/:family",
    component: _63b90a96,
    name: "cc-ba-family"
  }, {
    path: "/random/:random1/:random2",
    component: _0eeddb56,
    name: "random-random1-random2"
  }, {
    path: "/random/:random1/:random2/:random3",
    component: _19b4de00,
    name: "random-random1-random2-random3"
  }, {
    path: "/c/:path*",
    component: _43d7a5de,
    name: "catalog-map"
  }, {
    path: "/d/:path*",
    component: _2efe4ef8,
    name: "dynamic-map"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
